<script setup>
const props = defineProps({ blok: Object });

const { locale } = useI18n();
const allFaqs = await useFaqs().all();
const config = await useConfig();

function findFaqStory(uuid) {
  return allFaqs.find((f) => f.uuid === uuid);
}

function mapFaq(uuid) {
  const story = findFaqStory(uuid);
  if (!story) {
    return;
  }

  const content = story.content;

  return {
    title: content.title || story.name,
    text: content.text,
  };
}

function faqReferences(faq) {
  return faq.faqs
    .map((uuid) => {
      return mapFaq(uuid);
    })
    .filter((faq) => !!faq);
}

const faqs = [];
props.blok.blocks.forEach((faq) => {
  if (faq.faqs) {
    const references = faqReferences(faq);
    faqs.push(...references);
  } else {
    faqs.push(faq);
  }
});

// watch(() => locale.value, () => {
//   replaceTitle();
// });

const items = config.faq_checks.map((item) => ({
  text: item.text,
}));
</script>
<template>
  <BaseSection :blok="{ ...blok, blocks: [] }">
    <div class="w-layout-grid accordion-parent-grid">
      <div class="content">
        <div class="content-sticky">
          <div class="fade-in-on-scroll">
            <h2 class="section-heading">
              {{ config.faq_title }}
            </h2>
          </div>
          <div class="fade-in-on-scroll">
            <div v-html="renderRichText(config.faq_text)" />
          </div>
          <div class="fade-in-on-scroll">
            <ul role="list" class="list with-top-space">
              <li
                v-for="(item, i) in items"
                :key="i"
                class="list-item-with-checkicon"
              >
                <div>{{ item.text }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="w-node-_2204bd44-fcda-089d-5bdb-bc7e2b982f73-4e2e1336"
        class="content"
      >
        <div class="accordion-list faq-list accordions">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel
              v-for="(faq, i) in faqs"
              :key="i"
              :text="faq.text"
              :title="faq.title"
            ></v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </BaseSection>
</template>
<style lang="scss" scoped>
@media screen and (min-width: 991px) {
  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.2;
  }
}
</style>
<style lang="scss"></style>
